/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PartitionsIncrease,
    PartitionsIncreaseFromJSON,
    PartitionsIncreaseToJSON,
    PartitionsIncreaseResponse,
    PartitionsIncreaseResponseFromJSON,
    PartitionsIncreaseResponseToJSON,
    ReplicationFactorChange,
    ReplicationFactorChangeFromJSON,
    ReplicationFactorChangeToJSON,
    ReplicationFactorChangeResponse,
    ReplicationFactorChangeResponseFromJSON,
    ReplicationFactorChangeResponseToJSON,
    Topic,
    TopicFromJSON,
    TopicToJSON,
    TopicColumnsToSort,
    TopicColumnsToSortFromJSON,
    TopicColumnsToSortToJSON,
    TopicConfig,
    TopicConfigFromJSON,
    TopicConfigToJSON,
    TopicCreation,
    TopicCreationFromJSON,
    TopicCreationToJSON,
    TopicDetails,
    TopicDetailsFromJSON,
    TopicDetailsToJSON,
    TopicUpdate,
    TopicUpdateFromJSON,
    TopicUpdateToJSON,
    TopicsResponse,
    TopicsResponseFromJSON,
    TopicsResponseToJSON,
} from '../models';

export interface ChangeReplicationFactorRequest {
    clusterName: string;
    topicName: string;
    replicationFactorChange?: ReplicationFactorChange;
}

export interface CreateTopicRequest {
    clusterName: string;
    topicCreation?: TopicCreation;
}

export interface DeleteTopicRequest {
    clusterName: string;
    topicName: string;
}

export interface GetTopicConfigsRequest {
    clusterName: string;
    topicName: string;
}

export interface GetTopicDetailsRequest {
    clusterName: string;
    topicName: string;
}

export interface GetTopicsRequest {
    clusterName: string;
    page?: number;
    perPage?: number;
    showInternal?: boolean;
    search?: string;
    orderBy?: TopicColumnsToSort;
}

export interface IncreaseTopicPartitionsRequest {
    clusterName: string;
    topicName: string;
    partitionsIncrease?: PartitionsIncrease;
}

export interface UpdateTopicRequest {
    clusterName: string;
    topicName: string;
    topicUpdate?: TopicUpdate;
}

/**
 * TopicsApi - interface
 * 
 * @export
 * @interface TopicsApiInterface
 */
export interface TopicsApiInterface {
    /**
     * 
     * @summary changeReplicationFactor
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {ReplicationFactorChange} [replicationFactorChange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    changeReplicationFactorRaw(requestParameters: ChangeReplicationFactorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReplicationFactorChangeResponse>>;

    /**
     * changeReplicationFactor
     */
    changeReplicationFactor(requestParameters: ChangeReplicationFactorRequest, initOverrides?: RequestInit): Promise<ReplicationFactorChangeResponse>;

    /**
     * 
     * @summary createTopic
     * @param {string} clusterName 
     * @param {TopicCreation} [topicCreation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    createTopicRaw(requestParameters: CreateTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Topic>>;

    /**
     * createTopic
     */
    createTopic(requestParameters: CreateTopicRequest, initOverrides?: RequestInit): Promise<Topic>;

    /**
     * 
     * @summary deleteTopic
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    deleteTopicRaw(requestParameters: DeleteTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteTopic
     */
    deleteTopic(requestParameters: DeleteTopicRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary getTopicConfigs
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    getTopicConfigsRaw(requestParameters: GetTopicConfigsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TopicConfig>>>;

    /**
     * getTopicConfigs
     */
    getTopicConfigs(requestParameters: GetTopicConfigsRequest, initOverrides?: RequestInit): Promise<Array<TopicConfig>>;

    /**
     * 
     * @summary getTopicDetails
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    getTopicDetailsRaw(requestParameters: GetTopicDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicDetails>>;

    /**
     * getTopicDetails
     */
    getTopicDetails(requestParameters: GetTopicDetailsRequest, initOverrides?: RequestInit): Promise<TopicDetails>;

    /**
     * 
     * @summary getTopics
     * @param {string} clusterName 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {boolean} [showInternal] 
     * @param {string} [search] 
     * @param {TopicColumnsToSort} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    getTopicsRaw(requestParameters: GetTopicsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicsResponse>>;

    /**
     * getTopics
     */
    getTopics(requestParameters: GetTopicsRequest, initOverrides?: RequestInit): Promise<TopicsResponse>;

    /**
     * 
     * @summary increaseTopicPartitions
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {PartitionsIncrease} [partitionsIncrease] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    increaseTopicPartitionsRaw(requestParameters: IncreaseTopicPartitionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PartitionsIncreaseResponse>>;

    /**
     * increaseTopicPartitions
     */
    increaseTopicPartitions(requestParameters: IncreaseTopicPartitionsRequest, initOverrides?: RequestInit): Promise<PartitionsIncreaseResponse>;

    /**
     * 
     * @summary updateTopic
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {TopicUpdate} [topicUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApiInterface
     */
    updateTopicRaw(requestParameters: UpdateTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Topic>>;

    /**
     * updateTopic
     */
    updateTopic(requestParameters: UpdateTopicRequest, initOverrides?: RequestInit): Promise<Topic>;

}

/**
 * 
 */
export class TopicsApi extends runtime.BaseAPI implements TopicsApiInterface {

    /**
     * changeReplicationFactor
     */
    async changeReplicationFactorRaw(requestParameters: ChangeReplicationFactorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReplicationFactorChangeResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling changeReplicationFactor.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling changeReplicationFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/replications`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReplicationFactorChangeToJSON(requestParameters.replicationFactorChange),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReplicationFactorChangeResponseFromJSON(jsonValue));
    }

    /**
     * changeReplicationFactor
     */
    async changeReplicationFactor(requestParameters: ChangeReplicationFactorRequest, initOverrides?: RequestInit): Promise<ReplicationFactorChangeResponse> {
        const response = await this.changeReplicationFactorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * createTopic
     */
    async createTopicRaw(requestParameters: CreateTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Topic>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling createTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TopicCreationToJSON(requestParameters.topicCreation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicFromJSON(jsonValue));
    }

    /**
     * createTopic
     */
    async createTopic(requestParameters: CreateTopicRequest, initOverrides?: RequestInit): Promise<Topic> {
        const response = await this.createTopicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteTopic
     */
    async deleteTopicRaw(requestParameters: DeleteTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteTopic.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling deleteTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteTopic
     */
    async deleteTopic(requestParameters: DeleteTopicRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTopicRaw(requestParameters, initOverrides);
    }

    /**
     * getTopicConfigs
     */
    async getTopicConfigsRaw(requestParameters: GetTopicConfigsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TopicConfig>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopicConfigs.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling getTopicConfigs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/config`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopicConfigFromJSON));
    }

    /**
     * getTopicConfigs
     */
    async getTopicConfigs(requestParameters: GetTopicConfigsRequest, initOverrides?: RequestInit): Promise<Array<TopicConfig>> {
        const response = await this.getTopicConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTopicDetails
     */
    async getTopicDetailsRaw(requestParameters: GetTopicDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicDetails>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopicDetails.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling getTopicDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicDetailsFromJSON(jsonValue));
    }

    /**
     * getTopicDetails
     */
    async getTopicDetails(requestParameters: GetTopicDetailsRequest, initOverrides?: RequestInit): Promise<TopicDetails> {
        const response = await this.getTopicDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTopics
     */
    async getTopicsRaw(requestParameters: GetTopicsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicsResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopics.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.showInternal !== undefined) {
            queryParameters['showInternal'] = requestParameters.showInternal;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicsResponseFromJSON(jsonValue));
    }

    /**
     * getTopics
     */
    async getTopics(requestParameters: GetTopicsRequest, initOverrides?: RequestInit): Promise<TopicsResponse> {
        const response = await this.getTopicsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * increaseTopicPartitions
     */
    async increaseTopicPartitionsRaw(requestParameters: IncreaseTopicPartitionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PartitionsIncreaseResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling increaseTopicPartitions.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling increaseTopicPartitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/partitions`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PartitionsIncreaseToJSON(requestParameters.partitionsIncrease),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartitionsIncreaseResponseFromJSON(jsonValue));
    }

    /**
     * increaseTopicPartitions
     */
    async increaseTopicPartitions(requestParameters: IncreaseTopicPartitionsRequest, initOverrides?: RequestInit): Promise<PartitionsIncreaseResponse> {
        const response = await this.increaseTopicPartitionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateTopic
     */
    async updateTopicRaw(requestParameters: UpdateTopicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Topic>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateTopic.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling updateTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TopicUpdateToJSON(requestParameters.topicUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicFromJSON(jsonValue));
    }

    /**
     * updateTopic
     */
    async updateTopic(requestParameters: UpdateTopicRequest, initOverrides?: RequestInit): Promise<Topic> {
        const response = await this.updateTopicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
