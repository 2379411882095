/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KsqlCommand,
    KsqlCommandFromJSON,
    KsqlCommandToJSON,
    KsqlCommandResponse,
    KsqlCommandResponseFromJSON,
    KsqlCommandResponseToJSON,
    KsqlResponse,
    KsqlResponseFromJSON,
    KsqlResponseToJSON,
} from '../models';

export interface ExecuteKsqlRequest {
    clusterName: string;
    ksqlCommand?: KsqlCommand;
}

export interface ExecuteKsqlCommandRequest {
    clusterName: string;
    ksqlCommand?: KsqlCommand;
}

/**
 * KsqlApi - interface
 * 
 * @export
 * @interface KsqlApiInterface
 */
export interface KsqlApiInterface {
    /**
     * 
     * @summary executeKsql
     * @param {string} clusterName 
     * @param {KsqlCommand} [ksqlCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsqlApiInterface
     */
    executeKsqlRaw(requestParameters: ExecuteKsqlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<KsqlResponse>>>;

    /**
     * executeKsql
     */
    executeKsql(requestParameters: ExecuteKsqlRequest, initOverrides?: RequestInit): Promise<Array<KsqlResponse>>;

    /**
     * 
     * @summary executeKsqlCommand
     * @param {string} clusterName 
     * @param {KsqlCommand} [ksqlCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsqlApiInterface
     */
    executeKsqlCommandRaw(requestParameters: ExecuteKsqlCommandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<KsqlCommandResponse>>;

    /**
     * executeKsqlCommand
     */
    executeKsqlCommand(requestParameters: ExecuteKsqlCommandRequest, initOverrides?: RequestInit): Promise<KsqlCommandResponse>;

}

/**
 * 
 */
export class KsqlApi extends runtime.BaseAPI implements KsqlApiInterface {

    /**
     * executeKsql
     */
    async executeKsqlRaw(requestParameters: ExecuteKsqlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<KsqlResponse>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling executeKsql.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/ksql/v2`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KsqlCommandToJSON(requestParameters.ksqlCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KsqlResponseFromJSON));
    }

    /**
     * executeKsql
     */
    async executeKsql(requestParameters: ExecuteKsqlRequest, initOverrides?: RequestInit): Promise<Array<KsqlResponse>> {
        const response = await this.executeKsqlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * executeKsqlCommand
     */
    async executeKsqlCommandRaw(requestParameters: ExecuteKsqlCommandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<KsqlCommandResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling executeKsqlCommand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/ksql`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KsqlCommandToJSON(requestParameters.ksqlCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KsqlCommandResponseFromJSON(jsonValue));
    }

    /**
     * executeKsqlCommand
     */
    async executeKsqlCommand(requestParameters: ExecuteKsqlCommandRequest, initOverrides?: RequestInit): Promise<KsqlCommandResponse> {
        const response = await this.executeKsqlCommandRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
